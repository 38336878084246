@import '../../App.scss';

.contact-container{
    padding: 50px 0;
    height: 80%;
    background: linear-gradient(180deg, #0c0c1d, #111132);
    .contact-box{
        padding-top: 80px;
        padding-bottom: 80px;
        display: flex;
       
        width: 80%;
        margin: 0 auto;
        @include tablet {
            padding-top: 10px;
          flex-direction: column;

        }
        @include mobile {
            padding-top: 10px;
          flex-direction: column;

        }
        .part-one{
            width: 50%;
      
            padding: 10px;
            @include tablet {
                width: 100%;
              }
            @include mobile {
                width: 100%;
              }
            .work-h2{
                font-size: 100px;
                // line-height: 88px;
                color: #fff;
                margin:30px 0;
                @include tablet {
                    font-size: 70px;
                    text-align: center;
                }
                @include mobile {
                    font-size: 25px;
                    text-align: center;
                }
               
            }
            .div-contact-item{
                padding-bottom: 20px;
                @include tablet {
                    text-align: center;
                }
                @include mobile {
                    text-align: center;
                }
                .title-contact{
                    margin: 5px 0;
                    font-size: 22px;
                    color: #fff;
                }
                .p-contact{
                    color: #fff;
                    margin: 5px 0;
                }
            }
        }


        .part-two{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            @include tablet {
                width: 100%;
              }
            @include mobile {
                width: 100%;
              }
           
            form{
                width: 90%;
                display: flex;
                flex-direction: column;
                .input-form{
                    outline-color: transparent;
                    padding: 20px;
                    margin-top: 20px;
                    background-color: transparent;
                    border: 1px solid #fff;
                    border-radius: 5px;
                    color: #fff;
                    
                }
                .button-submit{
                    margin-top: 15px;
                    padding: 20px;
                    background-color: rebeccapurple; 
                    border: 1px solid rebeccapurple;
                    color: #fff;
                    border-radius: 5px;
                }
            }
        }
    }
}
.p-error{
    color: red;
    padding: 0;
    padding-top: 3px;
    margin: 0;
    font-size: 14px;
    @include mobile {
        font-size: 12px;
      }
}