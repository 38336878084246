@import '../../App.scss';

.container-question{
    position: absolute;
    z-index: 15;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center; 
    height: 100dvh;  
    // border: 1px solid salmon;
    width: 100%;
    // overflow-y: hidden;
     @include mobile{
           
        }
    .bg-question{
        // z-index: 20;
        // opacity: 0.8;
        // position: absolute;
        // width: 100%;
        // background-color: rgb(45, 44, 44);
        // height: 100%;
        // border: 1px solid white;
        // // top: 0;
        // // left: 0;
        // // bottom: 0;
        // // right: 0;

        // border: 10px solid salmon;
        // display: none;
        // position: fixed;
        z-index: 8;
        // left: 0;
        // top: 0;
        width: 100%;
        height: 100%;
        // overflow: hidden;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
       
        align-items: center;
         justify-content: center;
        @include mobile{
            width: 100%;
        }
   
    .form{
        // border: 10px solid red;
        // position: fixed;
        // top: 50%;
        // left: 50%;
        // height: auto;
        // transform: translate(-50%,-50%);
        z-index: 23;
        border-radius: 5px;
        padding: 20px;
        // glassomorphy
        background: rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);

        @include mobile{
            width: 65%;
        }
       .h3-question{
        font-size: 40px;
        color: #fff;
        @include mobile{
            font-size: 20px;
        }
       }
       .div-btn-question{
        display: flex;
        flex-direction: column;

        .input-name{
            padding: 15px;
            font-size: 20px;
            // glassomorphy
            border: none;
            background: rgba(255,255,255,0.05);
            backdrop-filter: blur(10px);
            border-top: 1px solid rgba(255,255,255,0.2);
            border-left: 1px solid rgba(255,255,255,0.2);
            box-shadow: 5px 5px 30px rgba(0,0,0,0.2);
            color: rgb(255, 255, 255);
            @include mobile{
                padding: 5px;
                font-size: 15px;
            }
            &:focus{
                outline: none;
            }
            &::placeholder{
               color: rgb(194, 187, 187);
            }
        }
        .btn-name{
            padding: 15px;
            margin-top: 15px;
            color: #fff;
            font-size: 20px;
            // glassomorphy
            background-color: rgba(255,255,255,0.1);
            border: 1px solid rgba(255,255,255,0.1);
            backdrop-filter: blur(30px);
            color: rgba(255,255,255,0.8);
            @include mobile{
                padding: 5px;
                font-size: 15px;
            }
        }
        .link-invite{
            margin-top: 10px;
            color: white;
        }
       }
    }
}
}
.p-error-question{
    font-size: 13px;
    color: red;
    margin: 2px 0;
    @include mobile{
        font-size: 12px;
    }
}
