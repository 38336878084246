@import '../../App.scss';

.nav-container{

    width: 25%;
    height: 100dvh;
    position: fixed;
    transition: all 0.4s ease;
    // border: 1px solid red;
    z-index: 3;
    clip-path: circle(6% at 50px 50px);
    background-color: #fff;
    @include tablet {
        // clip-path: circle(4% at 50px 50px);
        width: 50%;
        z-index: 30;
    }
    @include mobile {
        clip-path: circle(4% at 50px 50px);
        width: 80%;
        z-index: 30;
    }
    .nav-ul{
        list-style: none;
        // border: 1px solid red;
        padding: 0;
        margin: 0;
    
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include tablet {
        
          
            width:100%;
            //  top: 0;
            // bottom: 0;
            // left: 0;
            // right: 0;
            }
        @include mobile {
        
          
        width:90%;
        //  top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        }
        
        .nav-li{
            margin-bottom: 20px;
            transition: all 0.3s ease;
            &:hover{
                transition: all 0.3s ease;
                transform: scale(1.1);
            }
            a{
                font-size: 35px;
                color: black;
                text-decoration: none;
                @include mobile {
                    font-size: 20px;
                }
            }
        }
    }
}

.open{
    clip-path: circle(200% at 50px 50px);
   
}