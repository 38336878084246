@import '../../App.scss';

.pop-up{
    position: absolute;
    border: 1px solid greenyellow;
    left: 30%;
    // top: 250px;
    height: 150px;
    width: 400px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.19);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.4px);

    @include tablet{
        width: 80%;
        height: 250px;
        // margin: 0 auto;
        left: -190px;

    }
    @include mobile{
        width: 80%;
        height: 250px;
        // margin: 0 auto;
        left: -211px;

    }
    .cross{
        width: 20px;
        top: 10px;
        right: 10px;
        position: absolute;
        cursor: pointer;
    }
   
    .pop-up-container{
        width: 80%;

      
        h3{
            color: #fff;
            margin: 5px 0;
            font-size: 22px;
            text-align: center;
        }

        p{
            color: white;
            width: 100%;
            margin: 0 auto;
            font-size: 17px;
           
        }
    }
}