@import '../../App.scss';
.hero-conatiner{

    .hero{
        
        height: 100dvh;
        background: linear-gradient(180deg, #0c0c1d, #111132);
 
        position: relative;
  
        @include mobile {
            display: flex;
        flex-direction: column;
        }
    
        .wrapper{
       
            width: 50%;
      
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
         
            @include tablet {
               width: 100%;
               padding-top: 50px;
            height: 60%;
            }
            @include mobile {
                // max-width: 366px;
            // height: auto;
            padding-top: 50px;
            height: 60%;
            width: 100%;
            }
        
            .text-container{
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap:40px;
                @include tablet {
                    width: 100%;
              
                    gap:20px;
                }
                @include mobile {
                    width: 100%;
               
                    gap:20px;
                }

                h2{
                    font-size: 30px;
                    letter-spacing: 10px;
                    color: rebeccapurple ;
                    text-transform: uppercase;

                    @include tablet {
                        margin: 0;
                            text-align: center;
    
                        }
                    @include mobile {
                    margin: 0;
                        text-align: center;

                    }
                    a{
                        color: white;
                    }
                }
                h1{
                    font-size: 88px;
                    color: white;
                    @include tablet {
                        font-size: 60px;
                        text-align: center;
                        margin: 0 auto;
                        width: 60%;
                    }

                    @include mobile {
                        width: 80%;
                        font-size: 40px;
                        text-align: center;
               
                    }
                }
                .buttons{
                    z-index: 10;
                    display: flex;
                    gap: 10px;
                    @include tablet {
                    
                        flex-direction: column;
                        width: 30%;
                        margin: 0 auto;
                    }
                    @include mobile {
                    
                        flex-direction: column;
                        width: 80%;
                        margin: 0 auto;
                    }
                    .button-header{
                        padding: 20px;
                        border: 1px solid white;
                        border-radius: 10px;
                        background-color: transparent;
                        color: #fff;
                        cursor: pointer;
                        font-weight: 300;
                        text-decoration: none;
                        @include tablet {
                            text-align: center;
                            }
                        @include mobile {
                        text-align: center;
                        }

                    }
                }
                img{
                    width: 50px;
                }
            }
        }
      
        .image-container{
            height: 100%;
            width: 50%;
            position: absolute;
            top: 20%;
            right: 5%;
    
            @include tablet {
            
                right: inherit;
                top: inherit;
                left: 0;
                bottom: 0;
                width: 75%;
                height: 45%;
           
               display: flex;
         
               justify-content: end;
                
            }
            @include mobile {
            
                right: inherit;
                top: inherit;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 30%;
         
               display: flex;
       
               justify-content: end;
                
            }
        
            img{
               height: 80%;
              
               @include mobile {

                width: 100%;
                margin-left: 20px;
                object-fit: cover;
   
             
            }
            }


        }
    }
}