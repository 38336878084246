@import '../../App.scss';

.motivation-container{
    padding-top: 50px;
    padding-bottom: 100px;
    height: 100%;
    background: linear-gradient(180deg, #0c0c1d, #111132);
    @include mobile{
        padding-bottom: 20px;
    }
  
    .motivation-box{
        padding-top: 50px;
        width: 80%;
        margin: 0 auto;
        @include mobile{
            padding-top: 20px;
        }
        h2{
            margin: 0;
            color: #fff;
        }
        .p-motive{
            color: #fff;
            font-size: 18px;
            @include mobile{
                line-height: 25px;
            }
            .entreprise{   
                animation:3s linear 0s infinite alternate  boom;
            }
        }
    }
}


@keyframes boom {
    0% {
      color: rebeccapurple;
     
    }
    100% {
        color: #fff;
        font-size: 18px;
        // transform:scale(1.2);
    }
  }