.hamburger{
    position: absolute;
    top: 40px;
    left: 35px;
    z-index: 5;
  cursor: pointer;
  position: fixed;


    .lane{
        width: 30px;
        height: 4px;
        background-color: black;
        margin-bottom: 5px;
        
    }
}