@import '../../App.scss';

.skills-main-container{
    padding: 30px 0;
    height: 100%;
    background: linear-gradient(180deg, #0c0c1d, #111132);
    position: relative;
    
    
    .skills-stack-container{
        width: 70%;
        margin: 0 auto;
        padding-top: 50px;
        @include mobile{
            padding-top: 20px;
        }
        .skill-stack-title{
            color: rebeccapurple;
        }
        .skills-stack-box{
            display: flex;
            // padding-bottom: 50px;
            flex-wrap: wrap;
          
            margin: 0 auto;
            align-items: center;
            
            
            .skill{
                flex: 1 0 25%;
                font-size: 30px;
                color: #fff;
                cursor: crosshair;
                @include mobile{
                    font-size: 25px;
                    flex: 1 0 100%;
                }
            }
            .btn-reset{
                
                height: 35px;
                width: 120px;
                border: 1px solid #fff;
                display: inline-block;
                cursor: pointer;
                
            }
            
        }
    }



    .skills-container{
        // padding-top: 20px;
        width: 70%;
        margin: 0 auto;
        
        .skills-title{
            color: rebeccapurple;
        }
        .skills-box{
            display: flex;
            flex-wrap: wrap;

            .skills-item{
                flex: 1 0 25%;
                font-size: 30px;
                color: #fff;
                @include mobile{
                    flex: 1 0 100%;
                    font-size: 25px;
                }
            }
        }
        
    }
}