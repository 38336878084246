@import '../../App.scss';

.portfolio-container{
    padding: 50px 0;
    background: linear-gradient(180deg, #0c0c1d, #111132);
@include mobile{
    padding: 20px 0;
}
    .porfolio-section{
       padding-top: 100px;
       padding-bottom: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mobile{
            padding-top: 50px;
            padding-bottom: 30px;
        }

        .portfolio-box{
            display: flex;
            width: 80%;
            justify-content: space-around;
            // border: 1px solid salmon;
         
            gap: 25px;
            @include tablet{
              
                flex-direction: column;
             
             }
            @include mobile{
              
               flex-direction: column;
            
            }

            .item-portfolio{
                text-decoration: none;
                transition: all 0.3s ease;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid rebeccapurple;
             
               
                &:hover{
                    transition: all 0.3s ease;
                    transform: scale(1.1);
                    .img-porfolio{
                        transition: all 0.3s ease;
                        filter: grayscale(0.1);
                    }
                }
                .h2-item-portfolio{
                    font-size: 30px;
                    color: #fff;
                    margin: 5px 0;
                }
                .year-h3{
                    margin:3px 0;
                }
                .img-porfolio{
                    width: 100%;
                    transition: all 0.3s ease;
                    filter: grayscale(0.9);
                   
                }
            }
        }
    }
}