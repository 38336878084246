html{
    scroll-behavior: smooth;
    width: 100%;
    // enlever le bug de l'espace blanc 
    overflow-x: hidden;
}
body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family:"DM Sans", sans-serif;
    position: relative;
    // pour le popup
    overflow-x: hidden;

}
section{
    width: 100%;
}


.freez{
    overflow-y: hidden;
    // le bug de la taille autorise du overflow sur la longueur 
    height: 100dvh;
}
@mixin mobile {
    @media screen and (max-width: 440px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (max-width: 1366px) {
        @content;
    }
}
.divider{
    height: 3px;
    background-color: rebeccapurple;
    width: 40px;
    margin: 0 auto;
}
.main-section-h2-title{
    text-transform: uppercase;
    font-size: 50px;
    margin: 0;
    margin-bottom:15px;
    color: #fff;
    text-align: center;
    @include mobile {
        font-size: 25px;
        width: 80%;
        margin: 0 auto;
        padding-bottom: 15px;
        
    }
}